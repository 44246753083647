import React from 'react';
import ReactLazy, { LazyLoadProps } from 'react-lazyload';
import { makeStyledComponent } from './Elements';

const LazyUnstyled: React.FC<LazyLoadProps> = (props) => {
  const [didMount, setDidMount] = React.useState(false);

  // Detect if we're in a server-side rendered context or not.
  // If it's not mounted, then this is server-rendered and
  // shouldn't be included in the outputted HTML
  React.useEffect(() => setDidMount(true), []);

  return (
    <>
      <noscript>{props.children}</noscript>
      {didMount && <ReactLazy {...props}>{props.children}</ReactLazy>}
    </>
  );
};

export const Lazy = makeStyledComponent(LazyUnstyled);
